<template>
  <PageContent>
    <v-container fluid class="flex-grow-1 content">
      <v-row>
        <v-col md="12" style="height: calc(100vh - 100px)">
          <section>
            <p class="sections-title">Pedidos</p>
            <OrdersTable
              :exportOrder="true"
              :enabledStatusToFilter="enabledStatusList"
              :startRangeDateToFilter="startRangeDate"
              :estabelecimento="estabelecimento"
              :analyticsEventLabels="{
                ORDER_CANCELED: null,
                OPEN_ORDER_DETAILS: 'order_list_report_item',
                ORDER_SEARCH: null,
                ORDER_DATE_FILTER: 'order_list_report_search_date',
                ORDER_STATUS_FILTER: 'order_list_report_search_status',
                ORDER_DOWNLOAD: 'order_list_report_download'
              }"
              :initialStatusFilter="initialStatusFilter"
            />
          </section>
        </v-col>
      </v-row>
    </v-container>
  </PageContent>
</template>

<script>
import OrdersTable from '../components/order/OrdersTable.vue'
import { mapState } from 'vuex'
import PageContent from '@/components/shared/PageContent.vue'
import { ORDER_STATUS } from '@/util/constants'
import { addMonths, format, startOfMonth } from 'date-fns'
import { resetSession } from '@/plugins/axios'

export default {
  components: {
    PageContent,
    OrdersTable
  },
  data() {
    return {
      enabledStatusList: [
        ORDER_STATUS.ENTREGUE,
        ORDER_STATUS.ENVIADO,
        ORDER_STATUS.CANCELADO
      ],
      startRangeDate: format(
        startOfMonth(addMonths(new Date(), -12)),
        'yyyy-MM-dd'
      )
    }
  },
  computed: {
    ...mapState(['estabelecimento']),
    initialStatusFilter() {
      return `${ORDER_STATUS.ENTREGUE.key},${ORDER_STATUS.ENVIADO.key},${ORDER_STATUS.CANCELADO.key}`
    }
  },
  methods: {},
  mounted() {
    this.$gtag.event('order_list_report')
  },
  created() {
    this.user = this.$store.getters.usuarioLogado
    if (!this.user) {
      resetSession()
      return
    }
  }
}
</script>

<style></style>
