<template>
  <div class="chip">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: 'SelfSignUpChip'
}
</script>

<style scoped>
.chip {
  border: 1px solid #5fd564;
  color: #469d4a;
  padding: 12px;
  font-size: 18px;
  line-height: 21.78px;
  font-weight: 600;
  border-radius: 4px;
}
</style>
