import { Client } from '@stomp/stompjs'
import store from '../plugins/store.js'

let instance = null

export class WebSocketHandler {
  constructor(vueContext) {
    this.vueContext = vueContext
    this.client = null
    this.isLoggedOut = false

    if (!instance) {
      instance = this
    } else {
      return instance
    }
  }

  disconnectWebsocket() {
    this.client.deactivate()
    this.isLoggedOut = true
  }

  init() {
    const me = this
    this.client = new Client({
      brokerURL: process.env.VUE_APP_WS_URL,
      connectHeaders: {
        login: sessionStorage.getItem('access_token')
      },
      reconnectDelay: 5000,
      heartbeatIncoming: 25000,
      heartbeatOutgoing: 25000
    })

    this.subscribeTopics()
    this.client.activate()

    this.client.onWebSocketClose = function () {
      if (!me.isLoggedOut) {
        me.client.connectHeaders = {
          login: sessionStorage.getItem('access_token')
        }
        me.client.activate()
        me.subscribeTopics()
      }
    }
  }

  subscribeTopics() {
    const estabelecimento = store.getters.getEstabelecimento
    const context = this

    const subscriptions = [
      {
        topic: `/topic/nps/${estabelecimento.id}`,
        event: 'callNpsView'
      },
      {
        topic: `/topic/notification/${estabelecimento.id}`,
        event: 'callNotificationModal'
      },
      {
        topic: `/topic/order-confirmed/${estabelecimento.id}`,
        event: 'newOrderArrived'
      }
    ]

    this.client.onConnect = function () {
      subscriptions.forEach((subscription) => {
        context.client.subscribe(subscription.topic, (data) => {
          if (data !== undefined && data != null) {
            context.vueContext.$root.$emit(
              subscription.event,
              JSON.parse(data.body)
            )
          }
        })
      })
    }
  }
}
