<template>
  <s-main-container>
    <s-fields-wrapper>
      <v-col cols="12">
        <s-title
          >Selecione o horário de funcionamento para os pedidos da sua
          loja</s-title
        >
      </v-col>
      <v-col cols="12">
        <span class="text-body-1">
          <strong>Lembre-se:</strong> sua loja só aparecerá como aberta caso
          existam sacolas disponíveis</span
        >
      </v-col>
    </s-fields-wrapper>
    <div v-if="loading" class="text-center">
      <v-progress-circular indeterminate color="primary"></v-progress-circular>
    </div>
    <s-fields-wrapper v-if="!loading">
      <v-col cols="6">
        <s-label>Hora início:</s-label>
        <date-picker
          v-model="openHour"
          type="time"
          format="HH:mm"
          valueType="format"
        ></date-picker>
      </v-col>
      <v-col cols="6">
        <s-label>Hora fim:</s-label>
        <date-picker
          v-model="closeHour"
          type="time"
          format="HH:mm"
          valueType="format"
          class="teste"
        ></date-picker>
      </v-col>
      <v-col cols="12" v-if="error">
        <v-alert dismissible type="error">{{ error }}</v-alert>
      </v-col>
      <v-col cols="12" class="mt-10">
        <v-btn
          :elevation="0"
          color="secondary"
          class="font-weight-bold"
          block
          x-large
          @click="nextStep"
          >Próximo</v-btn
        >
      </v-col>
    </s-fields-wrapper>
  </s-main-container>
</template>

<script>
import SelfSignUpLabel from '@/components/selfSignUp/Label.vue'
import SelfSignUpTitle from '@/components/selfSignUp/Title.vue'
import SelfSignUpMainContainer from '@/components/selfSignUp/Container.vue'
import SelfSignUpFieldsWrapper from '@/components/selfSignUp/FieldsWrapper.vue'
import DatePicker from 'vue2-datepicker'
import 'vue2-datepicker/index.css'
import { isAxiosError } from 'axios'

export default {
  name: 'SelfSignUpOpeningHours',
  data: () => ({
    error: null,
    loading: false,
    openHour: null,
    closeHour: null
  }),
  components: {
    's-label': SelfSignUpLabel,
    's-title': SelfSignUpTitle,
    's-main-container': SelfSignUpMainContainer,
    's-fields-wrapper': SelfSignUpFieldsWrapper,
    DatePicker
  },
  methods: {
    async nextStep() {
      this.loading = true

      try {
        await this.$store.dispatch('selfSignUp/submitOpeningHours', {
          openHour: this.openHour,
          closeHour: this.closeHour
        })

        this.$store.dispatch('selfSignUp/nextStep')
        this.$router.push('/self-sign-up/selling-model')
      } catch (e) {
        if (isAxiosError(e)) {
          this.error = e.response.data
        } else {
          this.error = 'Ocorreu um erro inesperado. Tente novamente mais tarde.'
        }
        this.loading = false
      }
    }
  },
  mounted: async function () {
    if (this.$store.state.selfSignUp.currentStep != 4) {
      this.$store.commit('selfSignUp/setCurrentStep', 4)
    }

    this.loading = true

    await this.$store.dispatch('selfSignUp/fetchOpeningHours')

    if (this.$store.state.selfSignUp.openingHours) {
      this.openHour = this.$store.state.selfSignUp.openingHours.openHour
      this.closeHour = this.$store.state.selfSignUp.openingHours.closeHour
    }

    this.loading = false

    this.$gtag.event('access_information_opening_hours')
  }
}
</script>

<style scoped>
.edit-data-text {
  font-size: 18px;
}

>>> .mx-input {
  height: 56px;
}
</style>
