var render = function render(){var _vm=this,_c=_vm._self._c;return _c('PageContent',[_c('v-container',{staticClass:"flex-grow-1 content",attrs:{"fluid":""}},[_c('v-row',[_c('v-col',{attrs:{"md":"6","cols":"12"}},[(!_vm.estabelecimento)?_c('v-progress-circular',{attrs:{"indeterminate":"","color":"primary"}}):_vm._e(),_c('v-row',[_c('v-col',{staticClass:"pr-0",attrs:{"cols":"3","sm":"2","md":"2","xl":"1"}},[_c('p',{staticClass:"sections-title"},[_vm._v("Vendas")])]),_c('v-col',{staticClass:"pl-0",staticStyle:{"display":"flex","align-items":"center"},attrs:{"cols":"9","sm":"10","md":"10","xl":"11"}})],1),(_vm.estabelecimento)?_c('statistics-view',{attrs:{"partner_id":_vm.estabelecimento.id}}):_vm._e(),_c('p',{staticClass:"sections-title mb-0"},[_vm._v("Sacolas Surpresa disponíveis")]),_c('v-row',[_c('v-col',{attrs:{"md":"12","dense":""}},[_c('gondola-section',{ref:"gondolaSection",on:{"addGlobalError":_vm.addGlobalError,"clearGlobalErrors":_vm.clearGlobalErrors}})],1)],1),_c('div',{staticClass:"invoices-container-hide"},[_c('p',{staticClass:"sections-title"},[_vm._v("Faturas e notas fiscais")]),_c('invoices-table',{attrs:{"estabelecimento":_vm.estabelecimento}})],1),_c('v-row',{staticClass:"invoices-container-bottom-hide"},[_c('v-col',[_c('p',{staticClass:"sections-title"},[_vm._v("Faturas e notas fiscais")]),_c('invoices-table',{attrs:{"estabelecimento":_vm.estabelecimento}})],1)],1),(!this.hiddenKraftBagRequestIsEnabled)?_c('v-row',{staticClass:"kraft-bag-requests"},[_c('v-col',[_c('p',{staticClass:"sections-title"},[_vm._v("Solicite Sacola Kraft")]),_c('kraf-bag-request-form',{attrs:{"partner":_vm.estabelecimento}})],1)],1):_vm._e()],1),_c('v-col',{attrs:{"md":"6","cols":"12"}},[(_vm.pedidos)?_c('section',{staticStyle:{"min-height":"100%"}},[_c('p',{staticClass:"sections-title"},[_vm._v("Pedidos")]),_c('orders-table',{ref:"ordersTable",attrs:{"estabelecimento":_vm.estabelecimento,"analyticsEventLabels":{
              ORDER_CANCELED: 'order_canceled',
              OPEN_ORDER_DETAILS: null,
              ORDER_SEARCH: 'order_search',
              ORDER_DATE_FILTER: null,
              ORDER_STATUS_FILTER: null,
              ORDER_DOWNLOAD: null
            }},on:{"getGondolasEvent":_vm.getGondolasEvent}})],1):_vm._e()])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }