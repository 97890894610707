<script>
import { toDinheiro } from '@/util/Formater.js'
import { mapState } from 'vuex'

export default {
  name: 'GondolaContentDetails',
  props: {
    value: Boolean,
    gondolaBagId: String
  },
  data() {
    return {
      bagWithContent: [],
      errorList: [],
      show: this.value,
      isLoading: true
    }
  },
  methods: {
    toDinheiro,
    getPartner() {
      return this.$store.getters.getEstabelecimento
    },
    getPartnerId() {
      return this.$store.getters.getEstabelecimento.id
    },
    close() {
      this.$emit('input', false)
    },
    itemUnitIsUnitary(item) {
      if (!item?.unit) return false
      return ['UN', 'KIT'].includes(item.unit.toUpperCase())
    },
    getBagContentProducts() {
      this.errorList = []
      if (this.gondolaBagId) {
        this.$http
          .get(
            `/v1/partners/${this.getPartnerId()}/content-gondolas/${
              this.gondolaBagId
            }`
          )
          .then((response) => {
            this.bagWithContent = response.data
            this.isLoading = false
          })
          .catch((error) => {
            this.errorList.push(error.response.data)
            this.isLoading = false
          })
      }
    }
  },
  computed: {
    ...mapState(['estabelecimento'])
  },
  watch: {
    value(val) {
      if (val) this.getBagContentProducts()
      this.show = val
    },
    show(val) {
      this.$emit('input', val)
    }
  }
}
</script>
<template>
  <v-container>
    <v-dialog v-model="show" persistent max-width="35rem">
      <v-card>
        <v-card-text>
          <div v-if="isLoading">
            <v-skeleton-loader type="article" />
          </div>
          <div v-else>
            <div class="dialog-container">
              <div>
                <p>
                  <span class="dialog-title">Detalhes da sacola surpresa</span>
                </p>
                <p class="mb-0">
                  <span class="dialog-info-description">Código:</span>
                  <span class="dialog-info-value">
                    {{ this.bagWithContent.tag }}
                  </span>
                </p>
                <p class="mb-0">
                  <span class="dialog-info-description">Tipo:</span>
                  <span class="dialog-info-value">
                    {{ this.bagWithContent.type }}
                  </span>
                </p>
                <p class="mb-0">
                  <span class="dialog-info-description">Categoria:</span>
                  <span class="dialog-info-value">
                    {{ this.bagWithContent.category }}
                  </span>
                </p>
                <p class="mb-0">
                  <span class="dialog-info-description">Quantidade:</span>
                  <span class="dialog-info-value">
                    {{ this.bagWithContent.quantity }}
                  </span>
                </p>
                <p class="mb-0">
                  <span class="dialog-info-description">Valor:</span>
                  <span class="dialog-info-value">
                    {{ toDinheiro(this.bagWithContent.reference_price) }}
                  </span>
                </p>
              </div>
              <div>
                <p class="container-bag-products-title">
                  <span class="dialog-title">Produtos adicionados</span>
                </p>
                <div class="container-bag-products-items ma-0">
                  <v-list class="list-bag-products-items ma-0">
                    <v-list-item
                      v-for="product in this.bagWithContent
                        .partner_bag_offer_products"
                      :key="product.id"
                    >
                      <v-list-item-content width="200">
                        <v-list-item-title class="list-item-title">
                          <span :title="product.name">
                            {{ product.name }}
                          </span>
                        </v-list-item-title>
                        <v-list-item-subtitle class="text-wrap">
                          x{{ product.quantity }}
                          {{
                            !itemUnitIsUnitary(product)
                              ? ` / ${product.unit}`
                              : ''
                          }}
                          <strong>Cod.:</strong>
                          {{ product.external_code }}
                        </v-list-item-subtitle>
                      </v-list-item-content>
                      <v-list-item-content>
                        <v-list-item-title
                          class="text-wrap list-item-title-value mr-1"
                        >
                          {{
                            toDinheiro(
                              product.unit_price ? product.unit_price : 0.0
                            )
                          }}
                        </v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list>
                </div>
              </div>
              <v-btn color="primary" @click="close">Fechar</v-btn>
            </div>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<style scoped>
.dialog-container div {
  width: 100%;
}
.dialog-container span {
  color: #000000;
}
.dialog-title {
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
}
.dialog-info-description {
  font-size: 16px;
  font-weight: 700;
}
.dialog-info-value {
  font-size: 16px;
}
.container-bag-products-items {
  height: 350px;
  overflow-y: auto;
  width: 100%;
}
.container-bag-products-title {
  margin-top: 32px;
  padding: 0px;
}
.list-bag-products-items {
  width: 100%;
}
.list-bag-products-items div {
  padding: 0px;
}
.list-item-title-value {
  font-size: 18px;
  display: flex;
  justify-content: right;
  align-items: right;
  text-align: right;
}
.list-item-title {
  line-height: 1.5;
  color: #3a373a;
}
</style>
