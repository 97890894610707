<script>
import { toDinheiro, toTelefone, formatLocaleDate } from '@/util/Formater.js'

export default {
  name: 'InvoicesTable',
  data() {
    return {
      invoices: [],
      pagination: {
        page: 1,
        total: 0,
        limit: 10,
        first: 0
      },
      tableFields: [
        {
          value: 'number',
          text: 'Número',
          align: 'center',
          sortable: false,
          width: '1%'
        },
        {
          value: 'reference_year_month',
          text: 'Mês de Referência',
          align: 'center',
          sortable: false,
          width: '1%'
        },
        {
          value: 'invoice_url',
          text: 'Fatura',
          align: 'center',
          sortable: false,
          width: '1%'
        },
        {
          value: 'tax_invoice_url',
          text: 'Nota Fiscal',
          align: 'center',
          sortable: false,
          width: '1%'
        },
        {
          value: 'payment_receipt_url',
          text: 'Comprovante',
          align: 'center',
          sortable: false,
          width: '1%'
        }
      ],
      loading: false
    }
  },
  props: {
    estabelecimento: {}
  },
  methods: {
    toDinheiro,
    toTelefone,
    formatLocaleDate,
    getInvoices() {
      this.erros = []
      this.loading = true
      this.$http
        .get(`/v1/partners/${this.estabelecimento.id}/invoices`)
        .then((response) => {
          this.invoices = response.data
        })
        .catch((error) => {
          this.erros.push(error.message)
        })
        .finally(() => {
          this.loading = false
        })
    },
    downloadInvoice(invoice) {
      this.$http({
        url: `/v1/partners/${this.estabelecimento.id}/invoices/${invoice.number}/download`,
        method: 'GET',
        responseType: 'blob'
      })
        .then((response) => {
          const blob = new Blob([response.data])
          const link = document.createElement('a')
          link.href = URL.createObjectURL(blob)
          link.download =
            invoice.reference_year_month + '_invoice_' + invoice.number + '.pdf'
          link.click()
          URL.revokeObjectURL(link.href)
        })
        .catch((error) => {
          this.erros.push(error)
        })
      this.$gtag.event('download_invoices_fatura')
    },
    downloadPaymentReceipt(invoice) {
      this.$http
        .get({
          url: `/v1/partners/${this.estabelecimento.id}/invoices/${invoice.number}/payment-receipt-download`,
          method: 'GET',
          responseType: 'blob'
        })
        .then((response) => {
          const blob = new Blob([response.data], { type: 'application/pdf' })
          const link = document.createElement('a')
          link.href = URL.createObjectURL(blob)
          link.download =
            invoice.reference_year_month +
            '_payment-receipt_' +
            invoice.number +
            '.pdf'
          link.click()
          URL.revokeObjectURL(link.href)
        })
        .catch((error) => {
          this.erros.push(error.response.data)
        })
      this.$gtag.event('download_invoices_comprovante')
    },
    openInNewTab(url) {
      window.open(url, '_blank', 'noreferrer')
      this.$gtag.event('download_invoices_nota_fiscal')
    }
  },
  created() {
    this.getInvoices()
  }
}
</script>

<template>
  <div>
    <v-card style="border-radius: 10px">
      <v-data-table
        id="invoice-table-id"
        ref="invoice-table"
        stacked="md"
        class="invoice-table"
        show-empty
        responsive
        hide-default-footer
        :loading="loading"
        loading-text="Carregando faturas..."
        no-data-text="Não existem faturas disponíveis"
        :items="invoices"
        :headers="tableFields"
      >
        <template v-slot:[`item.invoice_url`]="{ item }">
          <v-btn
            text
            icon
            color="primary"
            :disabled="!item.invoice_url || item.invoice_url === ''"
            @click="downloadInvoice(item)"
          >
            <v-icon> mdi-download-circle </v-icon>
          </v-btn>
        </template>
        <template v-slot:[`item.tax_invoice_url`]="{ item }">
          <v-btn
            text
            icon
            color="primary"
            :disabled="!item.tax_invoice_url || item.tax_invoice_url === ''"
            @click="openInNewTab(item.tax_invoice_url)"
          >
            <v-icon> mdi-download-circle </v-icon>
          </v-btn>
        </template>
        <template v-slot:[`item.payment_receipt_url`]="{ item }">
          <v-btn
            text
            icon
            color="primary"
            :disabled="
              !item.payment_receipt_url || item.payment_receipt_url === ''
            "
            @click="downloadPaymentReceipt(item)"
          >
            <v-icon> mdi-download-circle </v-icon>
          </v-btn>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>
