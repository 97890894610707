import Vue from 'vue'
import { toZipCode, toCnpj, toCpf, toTelefone } from '../../../util/Formater'
import { router } from '../../../main'

export const selfSignUpModule = {
  namespaced: true,
  state: {
    currentStep: 1,
    companyData: {
      id: '',
      name: '',
      phone: '',
      document: '',
      zipCode: '',
      streetName: '',
      streetNumber: '',
      complement: '',
      city: '',
      state: ''
    },
    responsibleData: {
      name: '',
      email: '',
      document: '',
      phone: ''
    },
    bagManagement: {
      bagCategory: '',
      bagTypes: [],
      availableValues: []
    },
    openingHours: {
      openHour: null,
      closeHour: null
    },
    sellingModel: {
      observation: ''
    },
    showTutorialVideo: false
  },
  mutations: {
    setCurrentStep(state, step) {
      state.currentStep = step
    },
    setCompanyData(state, data) {
      state.companyData = data
    },
    setResponsibleData(state, data) {
      state.responsibleData = data
    },
    setBagManagementData(state, data) {
      state.bagManagement = data
    },
    setOpeningHours(state, data) {
      state.openingHours = data
    },
    setSellingModel(state, data) {
      state.sellingModel = data
    },
    setShowTutorialVideo (state, data) {
      state.showTutorialVideo = data
    }
  },
  actions: {
    nextStep({ commit, state }) {
      commit('setCurrentStep', state.currentStep + 1)
    },
    async fetchCompanyData({ commit, getters: { parentEstabelecimento } }) {
      const partnerId = parentEstabelecimento.id
      const response = await Vue.prototype.$http.get(
        `/v1/partners/${partnerId}/details`
      )

      commit('setCompanyData', {
        id: response.data.id,
        name: response.data['razao_social'],
        phone: toTelefone(response.data.telefone || ''),
        document: toCnpj(response.data.documento || ''),
        zipCode: toZipCode(response.data.endereco['zip_code'] || ''),
        streetName: response.data.endereco['address'],
        streetNumber: response.data.endereco['number'],
        complement: response.data.endereco['complement'],
        city: response.data.endereco['city'],
        state: response.data.endereco['uf']
      })

      commit('setOpeningHours', {
        openHour: response.data['hora_inicio_retirada'],
        closeHour: response.data['hora_final_retirada']
      })

      commit('setSellingModel', {
        observation: response.data['takeout_instructions']
      })
    },
    async fetchResponsibleData({ commit, getters: { parentEstabelecimento } }) {
      const partnerId = parentEstabelecimento.id
      const response = await Vue.prototype.$http.get(
        `/v1/partners/${partnerId}/legal-partner-contacts`
      )

      commit('setResponsibleData', {
        name: response.data.name,
        email: response.data.email,
        document: toCpf(response.data.document || ''),
        phone: toTelefone(response.data.phone || '')
      })
    },
    async fetchBagManagementData({ commit }) {
      commit('setBagManagementData', {
        bagCategory: 'Diversos',
        bagTypes: ['Doce', 'Salgada', 'Mista'],
        availableValues: [
          { from: 'R$45,00', to: 'R$15,99' },
          { from: 'R$60,00', to: 'R$20,99' },
          { from: 'R$90,00', to: 'R$30,99' },
          { from: 'R$150,00', to: 'R$50,99' }
        ]
      })
    },
    async companyDataIsMissing({
      getters: { companyData, parentEstabelecimento }
    }) {
      if (companyData.id === parentEstabelecimento.id) {
        return false
      }
      return true
    },
    async fetchOpeningHours({ dispatch }) {
      const companyDataMissing = await dispatch('companyDataIsMissing')
      if (companyDataMissing) {
        await dispatch('fetchCompanyData')
      }
    },
    async fetchSellingModel({ dispatch }) {
      const companyDataMissing = await dispatch('companyDataIsMissing')
      if (companyDataMissing) {
        await dispatch('fetchCompanyData')
      }
    },
    async submitOpeningHours({ getters: { parentEstabelecimento } }, data) {
      const partnerId = parentEstabelecimento.id

      await Vue.prototype.$http.put(`/v1/partners/${partnerId}/hora_retirada`, {
        hora_inicio_retirada: data.openHour,
        hora_final_retirada: data.closeHour
      })
    },
    async submitSellingModel({ getters: { parentEstabelecimento } }, data) {
      const partnerId = parentEstabelecimento.id

      await Vue.prototype.$http.patch(
        `/v1/partners/${partnerId}/takeout-instructions`,
        {
          takeout_instructions: data.observation
        }
      )
    },
    async submitBrandingData({ getters: { parentEstabelecimento } }, data) {
      const partnerId = parentEstabelecimento.id
      const formData = new FormData()
      formData.append('logoFile', data.storeFiles)
      formData.append('bannerFile', data.brandingFiles)
      await Vue.prototype.$http.patch(`/v1/partners/${partnerId}/images`, formData)
    },
    async closeSignUpOnboarding({
      getters: { parentEstabelecimento },
      commit,
      dispatch
    }) {
      const partnerId = parentEstabelecimento.id
      let currentShown = []
      try {
        const parsedStored = JSON.parse(
          localStorage.getItem('food_onboarding_shown')
        )
        currentShown.push(...parsedStored)
      } catch (_) {}

      currentShown.push(partnerId)
      localStorage.setItem(
        'food_onboarding_shown',
        JSON.stringify(currentShown)
      )

      await dispatch('resetStore')

      commit('setShowTutorialVideo', true)

      router.push('/')
    },
    resetStore({ commit }) {
      commit('setCurrentStep', 1)
      commit('setCompanyData', {
        id: '',
        name: '',
        phone: '',
        document: '',
        zipCode: '',
        streetName: '',
        streetNumber: '',
        complement: '',
        city: '',
        state: ''
      })
      commit('setResponsibleData', {
        name: '',
        email: '',
        document: '',
        phone: ''
      })
      commit('setBagManagementData', {
        bagCategory: '',
        bagTypes: [],
        availableValues: []
      })
      commit('setOpeningHours', {
        openHour: null,
        closeHour: null
      })
      commit('setSellingModel', {
        observation: ''
      })
    }
  },
  getters: {
    parentEstabelecimento: (_, __, rootState) => {
      return rootState.estabelecimento
    },
    currentStep: (state) => {
      return state.currentStep
    },
    companyData: (state) => {
      return state.companyData
    },
    showTutorialVideo: (state) => {
      return state.showTutorialVideo
    }
  },
  setters: {
    currentStep: (state, step) => {
      state.currentStep = step
    }
  }
}
