<template>
  <v-row class="fields-wrapper">
    <slot></slot>
  </v-row>
</template>

<script>
export default {
  name: 'SelfSignUpFieldsWrapper'
}
</script>

<style scoped>
.fields-wrapper {
  max-width: 800px;
  margin: 0 auto;
}
</style>
