var render = function render(){var _vm=this,_c=_vm._self._c;return _c('nav',[_c('div',{staticClass:"box-line lateral-container-section",class:[_vm.showMenuContent == true ? 'hidden-menu' : 'shown-menu'],attrs:{"id":"lateral-container-collapsed"}},[_c('div',{staticClass:"menu-item mt-12"},[_c('v-icon',{class:[_vm.isHomePage == true ? 'selected-item' : 'menu-text'],attrs:{"size":"32"},on:{"click":function($event){return _vm.$router.push({ name: 'main-board' })}}},[_vm._v(" mdi-home-outline ")]),_c('v-icon',{staticClass:"menu-collapse",attrs:{"size":"42"},on:{"click":_vm.showMenu}},[_vm._v(" mdi-chevron-right ")])],1),_c('div',{staticClass:"menu-item mt-3"},[_c('v-icon',{class:[_vm.isOrderResumePage == true ? 'selected-item' : 'menu-text'],attrs:{"size":"32"},on:{"click":function($event){return _vm.$router.push({ name: 'orders-report' })}}},[_vm._v(" mdi-list-box-outline ")])],1)]),_c('div',{staticClass:"lateral-container-content box-line lateral-container-section",class:[_vm.showMenuContent == false ? 'hidden-menu' : 'shown-menu']},[_c('div',{staticClass:"menu-section d-flex flex-column",staticStyle:{"justify-content":"space-between","height":"100%"}},[_c('div',{staticClass:"menu-items"},[_c('div',{staticClass:"menu-item mt-12",staticStyle:{"justify-content":"flex-start"}},[_c('div',{staticStyle:{"display":"flex","align-items":"center"}},[_c('v-icon',{staticClass:"mr-2",class:[
                _vm.isHomePage == true ? 'selected-item' : 'menu-text',
                'mr-2'
              ],attrs:{"size":"32"},on:{"click":function($event){return _vm.$router.push({ name: 'main-board' })}}},[_vm._v(" mdi-home-outline ")]),_c('p',{class:[_vm.isHomePage == true ? 'selected-item' : 'menu-text'],staticStyle:{"margin":"0"},on:{"click":function($event){return _vm.$router.push({ name: 'main-board' })}}},[_vm._v(" Ínicio ")])],1),(_vm.screenSize != 'sm' && _vm.screenSize != 'xs')?_c('v-icon',{staticClass:"menu-collapse",attrs:{"size":"42"},on:{"click":_vm.showMenu}},[_vm._v(" mdi-chevron-left ")]):_vm._e()],1),_c('div',{staticClass:"menu-item mt-3"},[_c('div',{staticClass:"d-flex align-center"},[_c('p',{staticStyle:{"margin":"0"}}),_c('v-icon',{staticClass:"mr-2",class:[
                _vm.isOrderResumePage == true ? 'selected-item' : 'menu-text',
                'mr-2'
              ],attrs:{"size":"32"},on:{"click":function($event){return _vm.$router.push({ name: 'orders-report' })}}},[_vm._v(" mdi-list-box-outline ")]),_c('p',{class:[
                _vm.isOrderResumePage == true ? 'selected-item' : 'menu-text',
                'mr-2'
              ],staticStyle:{"margin":"0"},on:{"click":function($event){return _vm.$router.push({ name: 'orders-report' })}}},[_vm._v(" Relatório de pedidos ")])],1)])]),(_vm.screenSize == 'sm' || _vm.screenSize == 'xs')?_c('div',{staticClass:"mobile-menu-items mb-8"},[_c('div',{staticClass:"menu-item mt-12 justify-start"},[_c('div',{staticClass:"d-flex align-center"},[_c('v-icon',{staticClass:"menu-text mr-2",attrs:{"size":"32"},on:{"click":function($event){return _vm.$emit('openChangeHourPopup')}}},[_vm._v(" mdi-alarm ")]),_c('p',{staticClass:"menu-text",staticStyle:{"margin":"0"},on:{"click":function($event){return _vm.$emit('openChangeHourPopup')}}},[_vm._v(" Horário de retirada ")])],1)]),_c('div',{staticClass:"menu-item mt-3"},[_c('div',{staticClass:"d-flex align-center"},[_c('p',{staticStyle:{"margin":"0"}}),_c('v-icon',{staticClass:"mr-2",attrs:{"size":"32"},on:{"click":function($event){return _vm.$emit('logout')}}},[_vm._v(" mdi-logout ")]),_c('p',{staticClass:"menu-text",staticStyle:{"margin":"0"},on:{"click":function($event){return _vm.$emit('logout')}}},[_vm._v(" Sair ")])],1)])]):_vm._e()])])])
}
var staticRenderFns = []

export { render, staticRenderFns }