<script>
export default {
  name: 'NpsView',
  components: {},
  data() {
    return {
      valid: false,
      colors: ['red', 'yellow', 'green'],
      rating: 0,
      reason: '',
      showDialogNps: false,
      partnerId: null,
      npsId: null,
      loading: false,
      successMessage: null,
      errorMessage: null
    }
  },
  model: {},
  methods: {
    genColor(i) {
      if (i <= 6) return this.colors[0]
      if (i == 7 || i == 8) return this.colors[1]
      if (i > 8) return this.colors[2]
    },
    selectNps(props) {
      this.rating = props.index + 1

      if (this.rating > 0) {
        this.valid = true
      } else {
        this.valid = false
      }
    },
    validate() {
      if (this.valid) {
        this.postNPS()
      }
    },
    close() {
      this.loading = true

      this.$http
        .patch(`/v1/partners/${this.partnerId}/nps/${this.npsId}`, {
          dismissed: true
        })
        .then(() => {
          this.successMessage = 'Pesquisa postergada.'
        })
        .catch((error) => {
          this.errorMessage = error.response.data
        })
        .finally(() => {
          setTimeout(() => {
            this.loading = false
            this.showDialogNps = false
          }, 1000)
        })
    },
    postNPS() {
      this.loading = true

      this.$http
        .put(`/v1/partners/${this.partnerId}/nps/${this.npsId}`, {
          respondent_user: this.$store.getters.usuarioLogado.email,
          answer_rate: this.rating,
          answer_rate_description: this.reason
        })
        .then(() => {
          this.successMessage = 'Enviado!'
        })
        .catch((error) => {
          this.errorMessage = error.response.data
        })
        .finally(() => {
          setTimeout(() => {
            this.loading = false
            this.showDialogNps = false
          }, 1000)
        })
    }
  },
  mounted() {
    this.$root.$on('callNpsView', (npsData) => {
      this.npsId = npsData.id
      this.partnerId = npsData.partner_id
      this.showDialogNps = true
    })
  }
}
</script>

<template>
  <div>
    <v-dialog v-model="showDialogNps" persistent retain-focus width="600">
      <v-container class="white pa-10" fluid>
        <v-row>
          <v-col>
            <v-row>
              <v-col>
                <p class="text-lg-h6 ma-0">
                  Qual probabilidade de você indicar a Food To Save para outro
                  estabelecimento?
                </p>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-rating v-model="rating" length="10">
                  <template v-slot:item="props">
                    <v-icon
                      size="50"
                      :color="
                        props.isFilled
                          ? genColor(props.value)
                          : 'grey lighten-1'
                      "
                      @click="selectNps(props)"
                    > {{`mdi-numeric-${props.index + 1}-box`}}</v-icon>
                  </template>
                </v-rating>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-textarea
                  v-model="reason"
                  dense
                  row-height="20"
                  height="60"
                  auto-grow
                >
                  <template v-slot:label>
                    <div>
                      Qual motivo da sua nota? <small>(Opcional)</small>
                    </div>
                  </template>
                </v-textarea>
              </v-col>
            </v-row>
            <v-row class="mt-2">
              <v-col class="text-center">
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="disabled" elevation="4" @click="close"
                    >RESPONDER DEPOIS
                  </v-btn>
                  <v-btn
                    :color="valid ? 'primary' : 'disabled'"
                    elevation="4"
                    :disabled="!valid"
                    @click="validate"
                    >ENVIAR
                  </v-btn>
                </v-card-actions>
              </v-col>
            </v-row>
          </v-col>
          <v-overlay :value="loading">
            <v-progress-circular v-if="loading" indeterminate color="primary" />
          </v-overlay>
        </v-row>
      </v-container>
    </v-dialog>
  </div>
</template>

<style scoped>
.tittle {
  margin: -12px;
  margin-top: 5px;
  font-size: 0.9em;
}

.body {
  margin-top: 0px;
  padding: 12px;
  padding-top: 0px;
}

.body p {
  margin: 0px;
  font-size: 0.8em;
}

.margin-body {
  margin: -12px;
  margin-top: 0px;
}
</style>
