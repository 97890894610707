<template>
  <h1 class="s_title font-weight-bold">
    <slot></slot>
  </h1>
</template>

<script>
export default {
  name: 'SelfSignUpTitle'
}
</script>

<style scoped>
.s_title {
  color: #484548;
  font-size: 32px;
  line-height: 38.73px;
}
</style>
