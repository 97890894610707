<template>
  <label class="font-weight-bold mb-4">
    <slot></slot>
  </label>
</template>

<script>
export default {
  name: 'SelfSignUpLabel'
}
</script>

<style scoped>
label {
  color: #484548;
  font-size: 18px;
  line-height: 21.78px;
}
</style>
