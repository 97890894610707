<script>
import { mapGetters, mapState } from 'vuex'
import GondolaCard from '@/components/bag/GondolaCard.vue'
import { fetchBagsData } from '@/api/bagService.js'
import {
  fetchGondolaData,
  fetchContentGondolaData
} from '@/api/partnerService.js'
import {
  defaultGondola,
  partnerConfigurationModelType,
  BAG_TYPES_ORDER
} from '@/util/constants'
import { isAxiosError } from 'axios'

export default {
  name: 'GondolaTabs',
  components: {
    GondolaCard: GondolaCard
  },
  props: {},
  data() {
    return {
      gondolas: [],
      gondolasMap: new Map(),
      tabCategories: [],
      initialGondolas: [],
      screenSize: this.$vuetify.breakpoint.name,
      loadingGondolas: false,
      customPriceGondolaIsEnabled: false,
      contentGondolaBagEnabled: false
    }
  },
  methods: {
    async getGondolasData() {
      this.loadingGondolas = true

      try {
        this.$emit('clearGlobalErrors')
        const [bagsResponse, gondolaResponse, contentGondolaResponse] =
          await Promise.all([
            fetchBagsData(this.$store.getters.getEstabelecimento.id),
            fetchGondolaData(this.$store.getters.getEstabelecimento.id),
            this.contentGondolaBagEnabled
              ? fetchContentGondolaData(
                  this.$store.getters.getEstabelecimento.id
                )
              : Promise.resolve(null)
          ])

        this.processGondolas(
          bagsResponse,
          gondolaResponse,
          contentGondolaResponse
        )
      } catch (error) {
        if (!isAxiosError(error)) {
          return
        }
        if (
          error.response.status === 412 &&
          this.estabelecimentoTemCadastroPendente
        ) {
          this.$emit('addGlobalError', error.response.data.message)
        }
      } finally {
        this.loadingGondolas = false
      }
    },

    processGondolas(bagsResponse, gondolaResponse, contentGondolaResponse) {
      this.gondolas = []
      const bags = bagsResponse.data
      const remoteGondolas = gondolaResponse.data
      const contentGondolas = contentGondolaResponse?.data || []

      const mergeGondolas = (gondolaList) => {
        const mergedGondolas = gondolaList.concat(
          this.gondolas.filter(
            (gondolaToBeMerged) =>
              !gondolaList.some((existentGondola) => {
                if (gondolaToBeMerged.id && existentGondola.id) {
                  return gondolaToBeMerged.id === existentGondola.id
                } else {
                  return (
                    gondolaToBeMerged.cesta.id === existentGondola.cesta.id &&
                    gondolaToBeMerged.cesta.valor_referencia ===
                      existentGondola.cesta.valor_referencia &&
                    gondolaToBeMerged.cesta.tipo ===
                      existentGondola.cesta.tipo &&
                    (existentGondola.cesta.category
                      ? gondolaToBeMerged.cesta.category ===
                        (existentGondola.cesta.category ?? 'Sem categoria')
                      : true)
                  )
                }
              })
          )
        )

        this.gondolas = [...mergedGondolas]
      }

      bags?.forEach((bag) => {
        const actualGondola = remoteGondolas.find(
          ({ cesta }) =>
            cesta.id === bag.id &&
            cesta.valor_referencia === bag.valor_referencia &&
            cesta.tipo === bag.tipo &&
            (bag.category
              ? cesta.category === (bag.category ?? 'Sem categoria')
              : true)
        )

        if (!actualGondola) mergeGondolas([{ cesta: bag, quantidade: 0 }])
      })

      mergeGondolas(remoteGondolas)

      if (this.contentGondolaBagEnabled) {
        mergeGondolas(contentGondolas)
      }

      this.gondolasMap = this.gondolas.reduce((gondolaMap, gondola) => {
        const category = gondola.cesta.category
        const bagType = gondola.cesta.tipo

        if (!gondolaMap.has(category)) {
          gondolaMap.set(category, new Map())
        }

        const gondolaByType = gondolaMap.get(category)

        if (!gondolaByType.has(bagType)) {
          gondolaByType.set(bagType, [])
        }

        gondolaByType.get(bagType).push(gondola)

        return gondolaMap
      }, new Map())

      if (this.customPriceGondolaIsEnabled && !this.contentGondolaBagEnabled) {
        this.gondolasMap.forEach((byType, category) => {
          byType.forEach((bags, bagType) => {
            const customBag = {
              ...defaultGondola,
              cesta: {
                ...defaultGondola.cesta,
                category: category,
                tipo: bagType
              },
              category: category,
              tipo: bagType,
              customPriceBag: true
            }
            bags.push(customBag)
          })
        })
      }

      this.gondolasMap = this.orderGondolasMap()
    },

    orderByReferencePrice(bags) {
      return bags.sort((a, b) => {
        if (a.cesta.valor_referencia === null) return 1
        if (b.cesta.valor_referencia === null) return -1
        return a.cesta.valor_referencia - b.cesta.valor_referencia
      })
    },

    orderGondolasMap() {
      const orderedGondolasMap = new Map()

      const gondolaMapOrderedPerCategory = new Map(
        [...this.gondolasMap.entries()].sort((a, b) => {
          const firstCategory = a[0]?.toUpperCase()
          const secondCategory = b[0]?.toUpperCase()
          return firstCategory < secondCategory
            ? -1
            : firstCategory > secondCategory
            ? 1
            : 0
        })
      ).entries()

      for (const [category, bags] of gondolaMapOrderedPerCategory) {
        const orderedBags = new Map()

        const bagListOrderedPerType = new Map(
          [...bags.entries()].sort((a, b) => {
            return BAG_TYPES_ORDER.indexOf(a[0]) - BAG_TYPES_ORDER.indexOf(b[0])
          })
        ).entries()

        for (const [type, items] of bagListOrderedPerType) {
          orderedBags.set(type, this.orderByReferencePrice(items))
        }

        orderedGondolasMap.set(category, orderedBags)
      }
      return orderedGondolasMap
    },

    updateGondolaMap(data) {
      const { category, tipo: bagType } = data.gondolas[0]?.cesta
      if (category && this.gondolasMap.has(category)) {
        const actualGondolas =
          this.gondolasMap
            .get(category)
            .get(bagType)
            ?.filter((gondola) => gondola.cesta.tipo !== bagType) ?? []

        this.gondolasMap
          .get(category)
          .set(bagType, [...data.gondolas, ...actualGondolas])
      }
      this.gondolasMap = this.orderGondolasMap()
    },

    generateKey(category, type) {
      return `${category}-${type}`.toLocaleLowerCase()
    },

    async reloadGondolasMap() {
      await this.getGondolasData()
    }
  },
  computed: {
    ...mapState(['estabelecimento']),
    ...mapGetters(['estabelecimentoTemCadastroPendente']),
    hasKeysGondolaMap() {
      return this.gondolasMap?.keys()?.length > 1
    }
  },
  async mounted() {
    this.customPriceGondolaIsEnabled = Boolean(
      this.$store.getters.getEstabelecimento.configurations?.find(
        (config) =>
          config.name === partnerConfigurationModelType.BAG_DYNAMIC_PRICE_MODEL
      )?.enabled
    )
    
    this.contentGondolaBagEnabled = Boolean(
      this.$store.getters.getEstabelecimento.configurations?.find(
        (config) =>
          config.name === partnerConfigurationModelType.BAG_WITH_CONTENT_MODEL
      )?.enabled
    )

    await this.getGondolasData()
  }
}
</script>

<template>
  <div>
    <v-row
      justify="center"
      v-show="loadingGondolas"
      :class="{
        'mt-6': screenSize == 'xs'
      }"
    >
      <v-progress-circular
        v-show="loadingGondolas"
        indeterminate
        color="primary"
      />
    </v-row>
    <v-row class="pa-3 mt-0" v-show="!loadingGondolas">
      <v-tabs v-if="gondolasMap.size > 1" v-model="tabCategories">
        <v-tab v-for="category in gondolasMap?.keys()" :key="category">
          {{ category }}
        </v-tab>
      </v-tabs>
      <v-tabs-items v-model="tabCategories" v-if="gondolasMap.size > 1">
        <v-tab-item
          v-for="[category, bagsFromCategory] in gondolasMap"
          :key="category"
        >
          <v-row>
            <v-col
              cols="12"
              sm="4"
              class="mb-4 mt-3"
              :class="{
                'd-flex flex-row justify-center ':
                  (screenSize == 'sm') | (screenSize == 'xs')
              }"
              v-for="[bagType, bagsFromType] in bagsFromCategory"
              :key="generateKey(category, bagType)"
            >
              <gondola-card
                :configurations="estabelecimento.configurations"
                :initialGondolas="bagsFromType"
                :gondolaType="bagType"
                :gondolaKey="generateKey(category, bagType)"
                @updateGondolaMap="reloadGondolasMap"
              />
            </v-col>
          </v-row>
        </v-tab-item>
      </v-tabs-items>
      <v-col v-else-if="!gondolasMap?.size" cols="12" sm="12">
        <p>Você ainda não possui sacolas configuradas.</p>
      </v-col>
      <v-col
        v-else
        cols="12"
        sm="4"
        v-for="[bagType, bagsFromType] in gondolasMap.entries().next().value[1]"
        :key="bagType"
        class="d-flex"
        :class="{
          'justify-start': screenSize != 'xs',
          'justify-center': screenSize == 'xs'
        }"
        style="padding-left: 0px; padding-right: 0px"
      >
        <gondola-card
          :configurations="estabelecimento.configurations"
          :initialGondolas="bagsFromType"
          :gondolaType="bagType"
          :gondolaKey="
            generateKey(gondolasMap.entries().next().value[0], bagType)
          "
          @updateGondolaMap="reloadGondolasMap"
        />
      </v-col>
    </v-row>
  </div>
</template>

<style scoped></style>
