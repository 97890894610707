<template>
   <l-map
      v-if="showMap"
      :zoom="zoom"
      :bounds="bounds"
      :center="center"
      :options="mapOptions"
      style="width: 400px; height: 300px;"
      @update:zoom="zoomUpdate"
    >
      <l-tile-layer
        :url="url"
        :attribution="attribution"
      />
      <l-marker v-for="(m) in this.markers" :key="m.name" :lat-lng="m.point" :icon="getIcon(m)">      
      </l-marker>
    </l-map>
</template>

<script>
import 'leaflet/dist/leaflet.css';
import { LMap, LTileLayer, LMarker } from "vue2-leaflet";
import { icon, IconDefault } from "leaflet";

export default {
  name: "MapView",
  components: {
    LMap,
    LTileLayer,
    LMarker,
  },
  props: {
    markers: [],
  },
  data() {
    return {
      zoom: 13,
      center: this.markers[0].point,
      bounds: this.markers.map((m) => m.point),
      url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
      attribution:
        '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a>',
      currentZoom: 11.5,
      // currentCenter: latLng(47.41322, -1.219482),
      currentCenter: this.markers[0].point,
      showParagraph: false,
      mapOptions: {
        zoomSnap: 0.5
      },
      showMap: true,
     
    };
  },
  methods: {
    zoomUpdate(zoom) {
      this.currentZoom = zoom;
    },
    centerUpdate(center) {
      this.currentCenter = center;
    },
    showLongText() {
      this.showParagraph = !this.showParagraph;
    },
    getIcon(marker) {
      if(marker && marker.name === 'origin') {
        return icon({
          iconUrl: marker.pin.url,
          iconSize: [40, 40],
          iconAnchor: [20,40],
          className: 'origin-marker'
        });
      }else if (marker && marker.name === 'courier') {
        return icon({
          iconUrl: require("@/assets/images/map-courier.png"),
          iconSize: [50, 50],
          iconAnchor: [20,50]
        });
      }else if (marker && marker.name === 'destiny') {
        return icon({
          iconUrl: require("@/assets/images/map-foodsaver.png"),
          iconSize: [50, 50],
          iconAnchor: [20,50]
        });
      } else {
        return IconDefault;
      }
    }
  },
};
</script>

<style>
   .origin-marker {
    border-radius: 50%;
    border: 1px solid gray;
    box-shadow: 
     rgb(0, 0, 0) 0px 11px 15px -7px, 
     rgba(0, 0, 0, 0.14) 0px 24px 38px 3px, 
     rgba(0, 0, 0, 0.12) 0px 9px 46px 8px;
  }
</style>