<template>
  <v-app>
    <v-container fluid class="container-section">
      <div>
        <top-bar
          :showOptions="false"
          selfSignUp
          :logoUrl="estabelecimento && estabelecimento.url_logo"
          :name="estabelecimento && estabelecimento.nome"
          :startHour="estabelecimento && estabelecimento.hora_inicio_retirada"
          :endHour="estabelecimento && estabelecimento.hora_final_retirada"
          :partnerId="estabelecimento && estabelecimento.id"
        ></top-bar>
        <div class="d-flex s-wrapper">
          <v-stepper
            :value="currentStep"
            vertical
            non-linear
            :elevation="0"
            v-on:change="handleStepChange"
          >
            <v-stepper-step
              editable
              edit-icon="$complete"
              :complete="currentStep > 1"
              step="1"
              color="#005589"
              >Dados da sua loja
            </v-stepper-step>

            <v-stepper-step
              editable
              edit-icon="$complete"
              :complete="currentStep > 2"
              step="2"
              color="#005589"
              >Dados do responsável</v-stepper-step
            >

            <v-stepper-step
              editable
              :complete="currentStep > 3"
              step="3"
              color="#005589"
              >Gestão de sacolas</v-stepper-step
            >

            <v-stepper-step
              editable
              :complete="currentStep > 4"
              step="4"
              color="#005589"
              >Horário de funcionamento</v-stepper-step
            >

            <v-stepper-step
              editable
              edit-icon="$complete"
              :complete="currentStep > 5"
              step="5"
              color="#005589"
              >Modelo de venda</v-stepper-step
            >

            <v-stepper-step
              editable
              edit-icon="$complete"
              :complete="currentStep > 6"
              step="6"
              color="#005589"
              >Sua marca</v-stepper-step
            >
          </v-stepper>
          <div class="flex-grow-1">
            <router-view></router-view>
          </div>
        </div>
      </div>
    </v-container>
  </v-app>
</template>

<script>
import TopBar from '@/components/shared/TopBar.vue'
import { mapState } from 'vuex'

const stepRoutes = {
  1: 'self-sign-up-company-data',
  2: 'self-sign-up-responsible-data',
  3: 'self-sign-up-bag-management',
  4: 'self-sign-up-opening-hours',
  5: 'self-sign-up-selling-model',
  6: 'self-sign-up-branding'
}

export default {
  name: 'SelfSignUp',
  components: {
    TopBar
  },
  computed: {
    ...mapState('selfSignUp', ['currentStep']),
    ...mapState(['estabelecimento', 'loading'])
  },
  methods: {
    handleStepChange(step) {
      this.$router.push({ name: stepRoutes[step] })
    }
  },
  async mounted() {
    const shouldRedirectToSelfSignUpOnboarding = await this.$store.dispatch(
      'shouldRedirectToSelfSignUpOnboarding'
    )

    if (!shouldRedirectToSelfSignUpOnboarding) {
      this.$router.push('/')
      return
    }
  }
}
</script>

<style scoped>
.layout-wrapper {
  display: flex;
}

>>> #top-bar {
  border-bottom: 1px solid #d5d5d5;
}

.s-wrapper {
  min-height: calc(100vh - 100px);
  margin-top: 100px;
}

>>> .v-stepper.v-stepper--vertical {
  border-right: 1px solid #d5d5d5;
}

.container-section {
  padding: 0px;
}
</style>
