<script>
import { QrcodeStream } from 'vue-qrcode-reader'

export default {
  name: 'BarCodeScannerDialog',
  components: {
    QrcodeStream
  },
  data() {
    return {
      loading: true,
      paused: false,
      destroyed: false,
      camera: 'rear',
      result: '',
      showScanConfirmation: false,
      noRearCamera: false,
      noFrontCamera: false,
      errors: []
    }
  },
  methods: {
    async onInit(promise) {
      this.loading = true
      this.destroyed = false
      try {
        await promise
      } catch (error) {
        const triedFrontCamera = this.camera === 'front'
        const triedRearCamera = this.camera === 'rear'

        const cameraMissingError = error.name === 'OverconstrainedError'

        if (triedRearCamera && cameraMissingError) {
          this.noRearCamera = true
          this.camera = 'front'
        }

        if (triedFrontCamera && cameraMissingError) {
          this.noFrontCamera = true

          if (this.noFrontCamera && this.noRearCamera) {
            this.camera = 'auto'
          }
        }

        this.handleCameraErrors(error.name)
      } finally {
        this.loading = false
        this.showScanConfirmation = this.camera === 'off'
      }
    },
    handleCameraErrors(errorName) {
      switch (errorName) {
        case 'NotAllowedError':
          this.errors.push(
            'Você precisa liberar a permissão de acesso à camera.'
          )
          break
        case 'NotFoundError':
          this.errors.push('Nenhuma câmera disponível nesse dispositivo.')
          break
        case 'NotSupportedError':
          this.errors.push('A funcionalidade não está disponível.')
          break
        case 'NotReadableError':
          this.errors.push('A câmera já está em uso.')
          break
        case 'OverconstrainedError':
          // This error ocurrs when the lib tries to access all available cameras and can't init
          break
        case 'StreamApiNotSupportedError':
          this.errors.push(
            'A funcionalidade não é compatível com esse navegador.'
          )
          break
        case 'InsecureContextError':
          this.errors.push(
            'A funcionalidade não está disponível no contexto atual.'
          )
          break
        default:
          this.errors.push('Houve um erro ao ativar a câmera')
          break
      }
    },
    switchCamera() {
      switch (this.camera) {
        case 'front':
          this.camera = 'rear'
          break
        case 'rear':
          this.camera = 'front'
          break
        default:
          this.camera = 'auto'
          break
      }
    },
    unpause() {
      this.camera = 'auto'
    },
    pause() {
      this.camera = 'off'
    },
    async onDecode(detectedCode) {
      this.result = detectedCode

      this.pause()
      await this.timeout(200)

      this.closeDialog()
      this.$emit('searchProuctFromScanner', detectedCode)
    },

    timeout(ms) {
      return new Promise((resolve) => {
        window.setTimeout(resolve, ms)
      })
    },
    enableCamera() {
      this.errors = []
      this.destroyed = false
      this.showScanConfirmation = false
      this.camera = 'rear'
    },
    closeDialog() {
      this.destroyed = true
      this.errors = []
      this.$emit('closeDialogBarCodeScan')
    }
  }
}
</script>
<template>
  <v-container class="white scanner-container-dialog" p-8>
    <v-row>
      <v-col cols="12" align="right">
        <v-icon @click="closeDialog">mdi-close</v-icon>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" align="center">
        <QrcodeStream
          @decode="onDecode"
          :camera="camera"
          @init="onInit"
          :formats="['qr_code', 'code_128', 'ean_8', 'ean_13']"
          class="camera-wrapper"
          v-if="!destroyed"
        >
          <div v-show="loading" class="scan-confirmation">
            <v-progress-circular v-if="loading" indeterminate color="primary" />
          </div>
          <div v-show="showScanConfirmation" class="scan-confirmation">
            <v-icon> mdi-check-circle-outline </v-icon>
          </div>
          <button
            @click="switchCamera"
            class="switch-camera-button"
            v-if="!noRearCamera && !noFrontCamera"
          >
            <v-icon x-large class="switch-camera-icon"
              >mdi-camera-switch-outline</v-icon
            >
          </button>
          <v-alert dense type="error" v-if="this.errors?.length > 0">
            <ul>
              <li v-for="(error, _key, index) in this.errors" :key="index">
                {{ error }}
              </li>
            </ul>
          </v-alert>
        </QrcodeStream>
      </v-col>
    </v-row>
  </v-container>
</template>
<style scoped>
@import 'BarCodeScannerDialog.scss';
</style>
