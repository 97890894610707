<template>
  <v-app>
    <NpsView></NpsView>
    <transition name="pagina">
      <router-view></router-view>
    </transition>
  </v-app>
</template>

<script>
import { mapState, mapMutations, mapGetters } from 'vuex'
import NpsView from '@/components/nps/NpsView.vue'
import { WebSocketHandler } from '@/util/websocket'

export default {
  name: 'App',
  components: { NpsView },
  metaInfo() {
    return {
      title: 'Painel do Parceiro - Food To Save'
    }
  },
  computed: mapState(['loading', 'perguntaSessao']),
  methods: {
    ...mapMutations(['setEstabelecimento']),
    ...mapGetters(['getEstabelecimento'])
  },

  data() {
    return {
      estabelecimento: null
    }
  },

  created() {
    if (this.$store.getters.usuarioLogado && this.$route.name !== 'login') {
      const wsHandler = new WebSocketHandler(this)
      wsHandler.init()
    }
    // if (location.protocol != 'https:' && !location.href.startsWith('http://localhost:')){
    //    location.href = location.href.replace('http://','https://');
    // }
  },

  beforeMount() {
    this.estabelecimento = this.getEstabelecimento()
    if (this.estabelecimento) {
      this.setEstabelecimento(this.estabelecimento)
    }
  }
}
</script>

<style>
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;700&display=swap');
@import 'style/main.scss';
</style>
