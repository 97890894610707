<template>
  <div class="erros" v-show="erros.length">
    <p v-for="erro of erros" :key="erro">* {{ erro }}</p>
  </div>
</template>

<script>
export default {
  name: 'ErrorMessage',
  props: ['erros'],
  data() {
    return {}
  }
}
</script>

<style scoped>
.erros {
  border: 1px solid red;
  padding: 0.5rem;
  border-radius: 0.5rem;
}
.erros p {
  margin: 0.2rem;
  color: red;
  font-size: 1rem;
}
</style>
