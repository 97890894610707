<template>
  <img :src="iconSrc" class="gondola-icon" />
</template>

<script>
export default {
  props: {
    type: String
  },
  computed: {
    iconSrc() {
      switch (this.type?.toLocaleUpperCase()) {
        case 'DOCE':
          return require('@/assets/images/icon-park-outline_cake-five.svg')
        case 'MISTO':
          return require('@/assets/images/icon-park-outline_lunch-five.svg')
        case 'SALGADO':
          return require('@/assets/images/icon-park-outline_bread.svg')
        default:
          return ''
      }
    }
  }
}
</script>
