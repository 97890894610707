import LoginAdmin from '@/views/Login.vue'
import MainPageAdmin from '@/views/MainPage.vue'
import PageNotFound from '@/views/PageNotFound.vue'
import SelfSignUpCompanyData from '@/views/selfSignUp/CompanyData.vue'
import SelfSignUpResponsibleData from '@/views/selfSignUp/ResponsibleData.vue'
import SelfSignUpBagManagement from '@/views/selfSignUp/BagManagement.vue'
import SelfSignUpOpeningHours from '@/views/selfSignUp/OpeningHours.vue'
import SelfSignUpSellingModel from '@/views/selfSignUp/SellingModel.vue'
import SelfSignUpBranding from '@/views/selfSignUp/Branding.vue'
import SelfSignUp from '@/views/selfSignUp/SelfSignUp.vue'
import OrdersReportPage from '@/views/OrdersReportPage.vue'

export const routes = [
  { path: '', component: MainPageAdmin, name: 'home' },
  { path: '/login', component: LoginAdmin, name: 'login' },
  { path: '/main-board', component: MainPageAdmin, name: 'main-board' },
  { path: '*', component: PageNotFound, name: 'page-not-found' },
  { path: '/orders-report', component: OrdersReportPage, name: 'orders-report' },
  {
    path: '/self-sign-up',
    name: 'self-sign-up',
    component: SelfSignUp,
    children: [
      {
        path: 'company-data',
        component: SelfSignUpCompanyData,
        name: 'self-sign-up-company-data'
      },
      {
        path: 'responsible-data',
        component: SelfSignUpResponsibleData,
        name: 'self-sign-up-responsible-data'
      },
      {
        path: 'bag-management',
        component: SelfSignUpBagManagement,
        name: 'self-sign-up-bag-management'
      },
      {
        path: 'opening-hours',
        component: SelfSignUpOpeningHours,
        name: 'self-sign-up-opening-hours'
      },
      {
        path: 'selling-model',
        component: SelfSignUpSellingModel,
        name: 'self-sign-up-selling-model'
      },
      {
        path: 'branding',
        component: SelfSignUpBranding,
        name: 'self-sign-up-branding'
      }
    ]
  }
]
