<template>
  <div id="app">
    <TopBar ref="topbar"></TopBar>
    <SidebarMenu
      @openChangeHourPopup="openChangeHourPopup"
      @logout="logout"
    ></SidebarMenu>
    <div class="main-content">
      <slot></slot>
    </div>
  </div>
</template>

<script>
import SidebarMenu from '@/components/shared/SidebarMenu.vue'
import TopBar from '@/components/shared/TopBar.vue'

export default {
  components: {
    SidebarMenu,
    TopBar
  },
  methods: {
    openChangeHourPopup() {
      this.$refs.topbar.openDialogChangeHour()
    },
    logout() {
      this.$refs.topbar.logout()
    }
  }
}
</script>

<style>
#app {
  height: 100vh;
  display: flex;
  flex-direction: column;
  background-color: rgb(244, 244, 244);
}

.main-content {
  margin-left: 100px;
  margin-top: 100px;
  overflow: auto;
}

@media screen and (max-width: 960px) {
  .main-content {
    margin-left: 0px;
  }
}
</style>
