var render = function render(){var _vm=this,_c=_vm._self._c;return _c('PageContent',[_c('v-container',{staticClass:"flex-grow-1 content",attrs:{"fluid":""}},[_c('v-row',[_c('v-col',{staticStyle:{"height":"calc(100vh - 100px)"},attrs:{"md":"12"}},[_c('section',[_c('p',{staticClass:"sections-title"},[_vm._v("Pedidos")]),_c('OrdersTable',{attrs:{"exportOrder":true,"enabledStatusToFilter":_vm.enabledStatusList,"startRangeDateToFilter":_vm.startRangeDate,"estabelecimento":_vm.estabelecimento,"analyticsEventLabels":{
              ORDER_CANCELED: null,
              OPEN_ORDER_DETAILS: 'order_list_report_item',
              ORDER_SEARCH: null,
              ORDER_DATE_FILTER: 'order_list_report_search_date',
              ORDER_STATUS_FILTER: 'order_list_report_search_status',
              ORDER_DOWNLOAD: 'order_list_report_download'
            },"initialStatusFilter":_vm.initialStatusFilter}})],1)])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }