export const defaultGondola = {
  cesta: {
    category: null,
    content: null,
    descricao: null,
    id: null,
    partner_id: null,
    tag: null,
    tipo: null,
    valor_referencia: null,
    valor: null
  },
  quantidade: 0
}

export const contentGondola = {
  cesta: {
    category: '',
    content: '',
    descricao: '',
    id: 0,
    partner_id: '',
    tipo: '',
    valor: 0,
    valor_referencia: 0,
    tag: ''
  },
  quantidade: 0
}

export const bagModelType = {
  BAG_FIXED_PRICE: 'BAG_FIXED_PRICE',
  BAG_DYNAMIC_PRICE: 'BAG_DYNAMIC_PRICE',
  BAG_WITH_CONTENT_MODEL: 'BAG_WITH_CONTENT_MODEL'
}

export const partnerConfigurationModelType = {
  BAG_DYNAMIC_PRICE_MODEL: 'BAG_DYNAMIC_PRICE_MODEL',
  BAG_WITH_CONTENT_MODEL: 'BAG_WITH_CONTENT_MODEL',
  HIDE_KRAFT_BAG_REQUEST: 'HIDE_KRAFT_BAG_REQUEST',
  ALLOW_CREATE_BAG_WITH_SELLING_PRICE: 'ALLOW_CREATE_BAG_WITH_SELLING_PRICE',
  CUSTOM_PRODUCT_WEIGHT_SEARCH: 'CUSTOM_PRODUCT_WEIGHT_SEARCH',
  CUSTOM_PRODUCT_UNIT_SEARCH: 'CUSTOM_PRODUCT_UNIT_SEARCH'
}

export const BAG_TYPE = {
  SAVORY: 'SALGADO',
  SWEET: 'DOCE',
  MIXED: 'MISTO'
}

export const BAG_TYPES_ORDER = [BAG_TYPE.SWEET, BAG_TYPE.MIXED, BAG_TYPE.SAVORY]

export const partnerStatusEnum = {
  CONFIRMACAO: 'CONFIRMACAO'
}

export const parterSignUpOriginEnum = {
  SELF_SIGNUP: 'SELF_SIGNUP'
}

export const ORDER_STATUS = {
  ENTREGUE: { key: 'ENTREGUE', label: 'Entregue' },
  RETIRAR: { key: 'RETIRAR', label: 'Retirar' },
  DELIVERY: { key: 'DELIVERY', label: 'Delivery' },
  ENVIADO: { key: 'ENVIADO', label: 'Enviado' },
  WAITING_PAYMENT: { key: 'WAITING_PAYMENT', label: 'Aguardando pagamento' },
  CANCELADO: { key: 'CANCELADO', label: 'Cancelado' }
}

export const ORDER_STATUS_LIST = [
  ORDER_STATUS.ENTREGUE,
  ORDER_STATUS.RETIRAR,
  ORDER_STATUS.DELIVERY,
  ORDER_STATUS.ENVIADO,
  ORDER_STATUS.WAITING_PAYMENT,
  ORDER_STATUS.CANCELADO
]
