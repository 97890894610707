<script>
import { mapState } from 'vuex'
import { evenRound } from '@/util/Formater.js'
import { vMaska } from 'maska'

export default {
  name: 'GondolaPriceDetails',
  props: {
    value: Boolean,
    customPrice: String,
    customReferencePrice: String,
    customPriceGondolaConfiguration: Object
  },
  data() {
    return {
      price: this.price,
      referencePrice: this.referencePrice,
      partnerDiscountPercentage: null,
      show: this.value
    }
  },
  methods: {
    evenRound,
    close() {
      this.$emit('confirmPriceDetails', {
        price: this.price,
        referencePrice: this.referencePrice
      })
      this.price = null
      this.referencePrice = null
    },
    calculateReferencePrice(value) {
      let eventValue
      if (value && typeof value === 'string') {
        eventValue = parseFloat(value.replace(',', '.').replace(' ', ''))
      }

      if (eventValue && !isNaN(eventValue)) {
        this.price = evenRound(eventValue ?? 0, 2).toLocaleString('pt-br', {
          style: 'decimal',
          minimumFractionDigits: 2
        })
        if (this.partnerDiscountPercentage) {
          this.referencePrice = evenRound(
            eventValue / (1 - this.partnerDiscountPercentage),
            2
          ).toLocaleString('pt-br', {
            style: 'decimal',
            minimumFractionDigits: 2
          })
        }
      }
    },
    calculatePrice(value) {
      let eventValue
      if (value && typeof value === 'string') {
        eventValue = parseFloat(value.replace(',', '.').replace(' ', ''))
      }

      if (eventValue && !isNaN(eventValue)) {
        this.referencePrice = evenRound(eventValue ?? 0, 2).toLocaleString(
          'pt-br',
          { style: 'decimal', minimumFractionDigits: 2 }
        )
        if (this.partnerDiscountPercentage) {
          this.price = evenRound(
            eventValue * (1 - this.partnerDiscountPercentage),
            2
          ).toLocaleString('pt-br', {
            style: 'decimal',
            minimumFractionDigits: 2
          })
        }
      }
    }
  },
  mounted() {
    this.partnerDiscountPercentage =
      this.customPriceGondolaConfiguration?.partner_configuration_params?.find(
        (param) => param.key === 'DYNAMIC_BAG_PRICE_DISCOUNT'
      )?.value
  },
  directives: { maska: vMaska },
  computed: {
    ...mapState(['estabelecimento'])
  },
  watch: {
    value(val) {
      this.show = val
    },
    customReferencePrice(val) {
      this.calculatePrice(val)
    }
  }
}
</script>
<template>
  <v-container>
    <v-dialog v-model="show" persistent max-width="35rem">
      <v-card>
        <v-card-text>
          <div class="dialog-container pb-3">
            <div>
              <p>
                <span class="dialog-title">Detalhes do preço da sacola</span>
              </p>
              <v-container class="pa-0 mb-4">
                <v-row no-gutters class="py-1 mb-3">
                  <v-col lg="8" md="8" sm="8" cols="6">
                    <span class="dialog-info-description">
                      Valor de referência:
                    </span>
                  </v-col>
                  <v-col lg="4" md="4" sm="4" cols="6" align="left">
                    <span class="mr-2 monetary-label">R$</span>
                    <input
                      placeholder="0,00"
                      type="text"
                      class="bag-custom-price"
                      v-model="referencePrice"
                      maxlength="6"
                      @change="(event) => calculatePrice(event.target.value)"
                      v-maska
                      data-maska="0,99"
                      data-maska-tokens="0:\d:multiple|9:\d:optional"
                    />
                  </v-col>
                </v-row>
                <v-row no-gutters class="py-1">
                  <v-col lg="8" md="8" sm="8" cols="6">
                    <span class="dialog-info-description">
                      Valor de venda com desconto:
                    </span>
                  </v-col>
                  <v-col lg="4" md="4" sm="4" cols="6" align="left">
                    <span class="mr-2">R$</span>
                    <input
                      placeholder="0,00"
                      type="text"
                      class="bag-custom-price"
                      v-model="price"
                      maxlength="6"
                      @change="
                        (event) => calculateReferencePrice(event.target.value)
                      "
                      v-maska
                      data-maska="0,99"
                      data-maska-tokens="0:\d:multiple|9:\d:optional"
                    />
                  </v-col>
                </v-row>
              </v-container>
            </div>
            <v-btn color="primary" @click="close">Confirmar</v-btn>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<style scoped>
.dialog-container div {
  width: 100%;
}
.dialog-container span {
  color: #000000;
}
.dialog-title {
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
}
.dialog-info-description {
  font-size: 16px;
  font-weight: 500;
}
.dialog-info-value {
  font-size: 16px;
}
input.bag-custom-price {
  width: 72px;
  text-align: right;
  font-size: 16px;
  border-style: outset;
}
input.bag-custom-price::placeholder {
  color: #615f61;
}
</style>
