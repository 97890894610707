<script>
export default {
  name: 'StatisticsView',
  props: {
    partner_id: String
  },
  components: {},
  data() {
    return {
      erros: [],
      totalOrders: null,
      totalBags: null,
      totalCO2: null,
      totalRevenue: null,
      averageEvaluation: null,
      countEvaluation: null,
      topSaver: false
    }
  },
  mounted() {
    this.getStats()
  },
  methods: {
    getStats: async function () {
      await this.$http
        .get(`/v1/partners/${this.partner_id}/stats`)
        .then((r) => {
          if (r && r.data) {
            this.totalOrders = r.data.total_orders
            this.totalBags = r.data.total_bags
            this.totalCO2 = r.data.total_co2
            this.totalRevenue = r.data.total_revenue
            this.averageEvaluation = r.data.average_evaluation
            this.countEvaluation = r.data.count_evaluation
            this.topSaver = r.data.top_saver
          }
        })
        .catch((e) => {
          if (e && e.response && e.response.data) {
            this.erros.push(e.response.data)
          }
        })
    }
  }
}
</script>

<template>
  <v-col v-if="this.totalOrders">
    <v-row class="d-flex flex-row" dense>
      <v-card class="stats-card rounded-lg d-flex flex-column ma-1 pa-4">
        <v-row class="flex-grow-0">
          <p class="text--secondary text-capitalize text-caption stats-name">
            Pedidos
          </p>
        </v-row>
        <v-row class="flex-grow-1 align-self-center align-center"
          ><p
            class="text--primary font-weight-bold text-lg-h6 text-md-subtitle-2"
          >
            {{ new Intl.NumberFormat().format(this.totalOrders) }}
          </p></v-row
        >
      </v-card>
      <v-card class="stats-card rounded-lg d-flex flex-column ma-1 pa-4">
        <v-row class="align-start flex-grow-0"
          ><p class="text--secondary text-capitalize text-caption stats-name">
            Sacolas
          </p></v-row
        >
        <v-row class="flex-grow-1 align-self-center align-center"
          ><p
            class="text--primary font-weight-bold text-lg-h6 text-md-subtitle-2"
          >
            {{ new Intl.NumberFormat().format(this.totalBags) }}
          </p></v-row
        >
      </v-card>

      <v-card class="stats-card rounded-lg d-flex flex-column ma-1 pa-4">
        <v-row class="flex-grow-0"
          ><p class="text--secondary text-capitalize text-caption stats-name">
            Kg CO<sup>2</sup>
          </p></v-row
        >
        <v-row class="flex-grow-1 align-self-center align-center"
          ><p
            class="text--primary font-weight-bold text-lg-h6 text-md-subtitle-2"
          >
            {{ new Intl.NumberFormat().format(this.totalCO2) }}
          </p></v-row
        >
      </v-card>

      <!-- <v-card class="stats-card rounded-lg d-flex flex-column ma-1 pa-4">
          <v-row class="flex-grow-0">
          <p class="text--secondary text-capitalize text-caption stats-name" style="word-break: break-word">
              Faturamento <sup>(R$)</sup>
            </p>
            </v-row>
          <v-row class="flex-grow-1 align-self-center align-center"
            ><p class="text--primary font-weight-bold text-lg-h6 text-md-subtitle-2">
              {{ new Intl.NumberFormat().format(this.totalRevenue) }}
            </p></v-row
          >
        </v-card> -->

      <v-card class="stats-card rounded-lg d-flex flex-column ma-1 pa-4">
        <v-row class="flex-grow-0"
          ><p
            class="text--secondary text-capitalize text-caption stats-name"
            style="word-break: break-word"
          >
            Nota 0-5
            <sup>{{
              this.countEvaluation ? '(' + this.countEvaluation + ')' : ''
            }}</sup>
          </p></v-row
        >
        <v-row class="flex-grow-1 align-self-center align-center">
          <p
            class="text--primary font-weight-bold text-lg-h6 text-md-subtitle-2"
          >
            {{
              this.averageEvaluation
                ? new Intl.NumberFormat().format(this.averageEvaluation)
                : '-'
            }}
          </p>
        </v-row>
        <v-img
          v-if="this.topSaver"
          class="image"
          src="@/assets/images/topsaver-seal.svg"
        />
      </v-card>
    </v-row>
  </v-col>
</template>

<style scoped>
.stats-card {
  width: 22%;
  height: 4em;
  position: relative;
}
.stats-card p {
  margin-bottom: 0 !important;
}
.stats-name {
  font-size: 10px !important;
  line-height: 0.8rem !important;
}

.image {
  position: absolute;
  top: 115%;
  left: 50%;
  transform: translate(-50%, -50%);
}
</style>
