<template>
  <s-main-container>
    <s-fields-wrapper>
      <v-col cols="12">
        <s-title>Conheça seu modelo de Sacola Surpresa!</s-title>
      </v-col>
    </s-fields-wrapper>
    <div v-if="loading" class="text-center">
      <v-progress-circular indeterminate color="primary"></v-progress-circular>
    </div>
    <s-fields-wrapper v-if="!loading">
      <v-col cols="12">
        <span class="text-body-1">
          Esta será a <strong>categoria</strong> das suas Sacolas Surpresa:
        </span>
      </v-col>
      <v-col cols="12">
        <div class="d-flex chips-container">
          <s-chip>{{ bagCategory }}</s-chip>
        </div>
      </v-col>
      <v-col cols="12">
        <span class="text-body-1"
          >Estes serão os <strong>tipos</strong> de suas Sacolas Surpresa:</span
        >
      </v-col>
      <v-col cols="12">
        <div class="d-flex chips-container">
          <s-chip v-for="bagType in bagTypes" :key="bagType">{{
            bagType
          }}</s-chip>
        </div>
      </v-col>
      <v-col cols="12" class="mt-6">
        <span class="text-body-1"
          >Estes serão os <strong>valores disponíveis</strong> para suas Sacolas
          Surpresa:</span
        >
      </v-col>
      <v-col cols="6">
        <div class="d-flex flex-column chips-container align-start">
          <s-chip v-for="value in availableValues" :key="value.to">
            <span class="font-weight-regular value-text">
              de
              <span class="text-decoration-line-through">{{ value.from }}</span>
              por {{ value.to }}
            </span></s-chip
          >
        </div>
      </v-col>
      <v-col cols="6">
        <div class="example-bag-wrapper">
          <img
            class="example-bag"
            src="@/assets/images/selfSignUp/example_bag.png"
          />
        </div>
      </v-col>
      <v-col cols="12">
        <v-btn
          :elevation="0"
          color="secondary"
          class="font-weight-bold"
          block
          x-large
          @click="nextStep"
          >Salvar</v-btn
        >
      </v-col>
    </s-fields-wrapper>
  </s-main-container>
</template>

<script>
import SelfSignUpTitle from '@/components/selfSignUp/Title.vue'
import SelfSignUpMainContainer from '@/components/selfSignUp/Container.vue'
import SelfSignUpFieldsWrapper from '@/components/selfSignUp/FieldsWrapper.vue'
import SelfSignUpChip from '@/components/selfSignUp/Chip.vue'

export default {
  name: 'SelfSignUpBagManagement',
  data: () => ({
    loading: false,
    bagCategory: '',
    bagTypes: [],
    availableValues: []
  }),
  components: {
    's-title': SelfSignUpTitle,
    's-main-container': SelfSignUpMainContainer,
    's-fields-wrapper': SelfSignUpFieldsWrapper,
    's-chip': SelfSignUpChip
  },
  methods: {
    nextStep() {
      this.$store.dispatch('selfSignUp/nextStep')
      this.$router.push('/self-sign-up/opening-hours')
    }
  },
  async mounted() {
    if (this.$store.state.selfSignUp.currentStep != 3) {
      this.$store.commit('selfSignUp/setCurrentStep', 3)
    }

    this.loading = true

    await this.$store.dispatch('selfSignUp/fetchBagManagementData')

    if (this.$store.state.selfSignUp.bagManagement) {
      this.bagCategory = this.$store.state.selfSignUp.bagManagement.bagCategory
      this.bagTypes = this.$store.state.selfSignUp.bagManagement.bagTypes
      this.availableValues =
        this.$store.state.selfSignUp.bagManagement.availableValues
    }

    this.loading = false

    this.$gtag.event('access_information_model_bags')
  }
}
</script>

<style scoped>
.edit-data-text {
  font-size: 18px;
}

.chips-container {
  gap: 20px;
}

.value-text {
  color: #484548;
}
</style>
