<script>
import Erro from '@/components/shared/Erro.vue'
import { validateEmail } from '@/util/Documento'
import { WebSocketHandler } from '@/util/websocket'

export default {
  name: 'LoginView',
  components: {
    'erro-msg': Erro
  },
  data() {
    return {
      errors: [],
      userLogin: null,
      password: null,
      showRecoverDialog: false,
      recoveryEmail: null,
      showRecoverSuccessDialog: false,
      loadingLoginButton: false,
      dialogErrors: []
    }
  },

  methods: {
    validateEmail,
    openRecoverDialog() {
      this.showRecoverDialog = true
      this.$gtag.event('login_cta_password_recovery')
    },
    resendPassword() {
      const data = {
        email: this.recoveryEmail
      }
      this.$gtag.event('login_modal_cta_password_recovery', {
        email: this.recoveryEmail
      })
      this.$http
        .post('/v1/public/reset-password', data)
        .then(() => {
          this.showRecoverDialog = false
          this.showRecoverSuccessDialog = true
        })
        .catch((error) => this.dialogErros.push(error.response.data))
    },
    closeSuccessDialog() {
      this.showRecoverSuccessDialog = false
    },
    logar() {
      this.errors = []
      this.loadingLoginButton = true

      if (!this.userLogin) {
        this.errors.push('Informe o usuário!')
      }
      if (!this.password) {
        this.errors.push('Informe a senha')
      }

      if (this.errors.length) {
        this.loadingLoginButton = false
        return
      }

      const data = {
        email: this.userLogin.trim().toLowerCase(),
        password: this.password.trim()
      }

      this.$store.commit('loading', true)

      this.$http
        .post('/v1/authentication', data)
        .then((response) => {
          sessionStorage.setItem('access_token', response.data.access_token)
          sessionStorage.setItem('refresh_token', response.data.refresh_token)
          const estabelecimento = response.data.partner
          const usuario = {
            id: estabelecimento.id,
            email: estabelecimento.email,
            //token: estabelecimento.senha,
            url_logo: estabelecimento.url_logo
          }
          this.$store.commit('usuarioLogado', usuario)
          this.$store.commit('setEstabelecimento', estabelecimento)
          this.$gtag.event('login_cta_sign_in', { email: data.email })

          const wsHandler = new WebSocketHandler(this)
          wsHandler.init()

          this.$store.dispatch('handleUsuarioLogadoRedirect')
        })
        .catch((error) => {
          this.errors.push(
            error.response?.data ?? 'Ocorreu um erro. Tente novamente.'
          )
        })
        .finally(() => {
          this.loadingLoginButton = false
          this.$store.commit('loading', false)
        })
    },
    openSelfSignup() {
      window.open(
        'https://autocadastro.foodtosave.com.br/',
        '_blank',
        'noreferrer'
      )
      this.$gtag.event('want_to_be_a_partner')
    }
  },
  watch: {
    recoveryEmail: {
      handler() {
        this.dialogErrors = []
        if (this.recoveryEmail.length === 0) {
          this.dialogErrors.push('Email cadastrado é obrigatório')
        }
        if (!validateEmail(this.recoveryEmail)) {
          this.dialogErrors.push('Email não é válido')
        }
      }
    }
  }
}
</script>
<template>
  <div class="page-container">
    <v-row no-gutters style="height: 100vh; width: 100%">
      <v-col
        cols="12"
        sm="12"
        md="6"
        lg="5"
        xl="5"
        class="d-flex align-center justify-center"
        style="flex-direction: column; position: relative"
      >
        <div class="login-data-container" data-app>
          <img
            class="login-img-logo"
            src="@/assets/images/logo-food-to-save-login_rebranding.svg"
          />
          <div class="title-container">
            <h1 class="title-label-h1">Área do parceiro</h1>
            <h2 class="title-label-h2">Por favor, faça login para continuar</h2>
          </div>
          <div class="dados">
            <div class="input-group">
              <input
                class="input-login"
                type="email"
                v-model="userLogin"
                placeholder="Digite seu e-mail"
              />
              <div class="password-container">
                <input
                  class="input-login"
                  type="password"
                  v-model="password"
                  placeholder="Digite sua senha"
                />
                <erro-msg :erros="errors"></erro-msg>
                <div>
                  <button
                    plain
                    :ripple="false"
                    text
                    @click="openRecoverDialog"
                    class="anchor-button forgot-password-text"
                  >
                    Esqueci minha senha
                  </button>
                </div>
              </div>
            </div>
            <v-btn
              color="primary"
              class="login-button pb-4 pt-4"
              :loading="loadingLoginButton"
              @click="logar"
              min-height="54px"
            >
              Entrar
            </v-btn>
            <div>
              <span class="title-label-h2">Quer ser parceiro? </span>
              <button
                plain
                :ripple="false"
                text
                @click="openSelfSignup"
                class="anchor-button selfsignup-text"
              >
                Clique aqui!
              </button>
            </div>

            <v-dialog max-width="35rem" v-model="showRecoverDialog">
              <div class="dialog-container">
                <v-img
                  alt="email"
                  src="@/assets/images/mail.png"
                  contain
                  width="150"
                  height="180"
                />
                <p>Insira o e-mail cadastrado para o envio da nova senha</p>
                <erro-msg :erros="dialogErrors"></erro-msg>
                <input
                  class="input"
                  type="email"
                  v-model="recoveryEmail"
                  placeholder="E-mail"
                />
                <button
                  :disabled="!validateEmail(this.recoveryEmail)"
                  class="button"
                  type="button"
                  @click="resendPassword"
                >
                  Confirmar
                </button>
              </div>
            </v-dialog>

            <v-dialog max-width="35rem" v-model="showRecoverSuccessDialog">
              <div class="dialog-container">
                <v-img
                  alt="email-send"
                  src="@/assets/images/mail-send.png"
                  contain
                  width="150"
                  height="180"
                />
                <p>Email enviado! Verifique sua caixa de entrada e spam</p>
                <button
                  class="button"
                  type="button"
                  @click="closeSuccessDialog"
                >
                  Fechar
                </button>
              </div>
            </v-dialog>
          </div>
        </div>
        <v-spacer />
        <div class="help-container">
          <span class="title-label-h2"
            >Precisa de ajuda?
            <span class="font-weight-bold">meajuda@foodtosave.com</span>
          </span>
        </div>
      </v-col>
      <v-col
        cols="7"
        md="6"
        lg="7"
        xl="7"
        class="login-img-container hidden-sm-and-down"
      >
        <img
          class="login-img"
          src="@/assets/images/login_background_image.svg"
        />
      </v-col>
    </v-row>
  </div>
</template>

<style scoped>
@import '../style/login.scss';
</style>
